import { VyVConsentPostRequest } from 'src/app/api/models/vyVConsentPostRequest';
import { VyVContractCheckIBANRequest } from 'src/app/api/models/vyVContractCheckIBANRequest';
import { VyVContractCreateRequest } from 'src/app/api/models/vyVContractCreateRequest';
import { VyVContractEnvoiAttestationBailleurInfo } from 'src/app/api/models/vyVContractEnvoiAttestationBailleurInfo';
import { VyVContractResiliationAncienneAssuranceInfo } from 'src/app/api/models/vyVContractResiliationAncienneAssuranceInfo';
import { VyVContractSaisiePaiement } from 'src/app/api/models/vyVContractSaisiePaiement';
import { VyVDevisCreateRequest } from 'src/app/api/models/vyVDevisCreateRequest';
import { VyVSharedCodeFormule } from 'src/app/api/models/vyVSharedCodeFormule';
import { VyVSharedProductCode } from 'src/app/api/models/vyVSharedProductCode';
import { VyVSignatureCreateRequest } from 'src/app/api/models/vyVSignatureCreateRequest';
import { VyVSignatureSignRequest } from 'src/app/api/models/vyVSignatureSignRequest';
import { VyVTarifCreateRequest } from 'src/app/api/models/vyVTarifCreateRequest';
import { VyVTarifEnfantInfo } from 'src/app/api/models/vyVTarifEnfantInfo';
import { VyVTarifSaisieAdresse } from 'src/app/api/models/vyVTarifSaisieAdresse';
import { VyVTarifSaisieHabitation } from 'src/app/api/models/vyVTarifSaisieHabitation';
import { VyVTarifSaisiePersonnalisation } from 'src/app/api/models/vyVTarifSaisiePersonnalisation';
import { PathType } from 'src/app/core/enums/path-type.enum';
import {
  EcheancePrelevement,
  Esh,
  Etage,
  Formule,
  MontantValeurObjets2000,
  NatureResidence,
  QualiteOccupant,
  Raison,
  SaisieBeneficiaire,
  SaisieChoixServices,
  SaisieFormule,
  SaisieHabitation,
  SaisieInfosSouscripteur,
  SaisiePaiement,
  SaisiePersonnalisation,
  SaisieSignature,
  Selection,
  Selectionne,
  SurfaceDependance,
  TypeBailleur,
  TypeHabitation,
  VoiesConsentement
} from 'src/app/core/models/form-state.model';

export class ApiRequestBodyUtils {
  static GenerateProductData(product: PathType, brand: string) {
    return product == PathType.HOUSING_PARIS
      ? VyVSharedProductCode.HP
      : product == PathType.HOUSING_SOLIDARITY
      ? VyVSharedProductCode.HS
      : product == PathType.HOUSING_TOURCOING
      ? VyVSharedProductCode.HT
      : brand === 'HM'
      ? VyVSharedProductCode.HM_HC
      : VyVSharedProductCode.HC;
  }

  static GenerateSaisieAdresseData(
    adresseType: Selectionne,
    adresseSelection: Selection | undefined,
    adresseManuelle: Esh | undefined,
    adresseEsh: Esh | undefined
  ): VyVTarifSaisieAdresse {
    switch (adresseType) {
      case Selectionne.Selection: {
        return {
          AdresseComplete: adresseSelection?.Adresse,
          NumeroDeRue: adresseSelection?.AdresseEsh.Numero!,
          NomDeRue: adresseSelection?.AdresseEsh.Rue!,
          CodePostal: adresseSelection?.AdresseEsh.CodePostal!,
          Ville: adresseSelection?.AdresseEsh.Ville!,
          CodePays: adresseSelection?.AdresseEsh.Pays!
        } as VyVTarifSaisieAdresse;
      }
      case Selectionne.Manuel: {
        return {
          NumeroEtRue: adresseManuelle?.NumeroEtRue!,
          CodePostal: adresseManuelle?.CodePostal!,
          Ville: adresseManuelle?.Ville!,
          Pays: adresseManuelle?.Pays!
        } as VyVTarifSaisieAdresse;
      }
      case Selectionne.Esh: {
        return {
          NomBailleur: adresseEsh?.NomBailleur!,
          NumeroEtRue: adresseEsh?.NumeroEtRue!,
          CodePostal: adresseEsh?.CodePostal!,
          Ville: adresseEsh?.Ville!,
          Pays: adresseEsh?.Pays!
        } as VyVTarifSaisieAdresse;
      }
      default: {
        return {} as VyVTarifSaisieAdresse;
      }
    }
  }

  static GenerateSaisieHabitationData(
    saisieHabitation: SaisieHabitation
  ): VyVTarifSaisieHabitation {
    return {
      TypeHabitation:
        saisieHabitation.TypeHabitation == TypeHabitation.Appartement
          ? VyVTarifSaisieHabitation.TypeHabitationEnum.Appartement
          : saisieHabitation.TypeHabitation == TypeHabitation.Maison
          ? VyVTarifSaisieHabitation.TypeHabitationEnum.Maison
          : VyVTarifSaisieHabitation.TypeHabitationEnum.Autre,
      QualiteOccupant:
        saisieHabitation.QualiteOccupant == QualiteOccupant.Locataire
          ? VyVTarifSaisieHabitation.QualiteOccupantEnum.Locataire
          : VyVTarifSaisieHabitation.QualiteOccupantEnum.Proprietaire,
      NatureResidence:
        saisieHabitation.NatureResidence == NatureResidence.Secondaire
          ? VyVTarifSaisieHabitation.NatureResidenceEnum.Secondaire
          : VyVTarifSaisieHabitation.NatureResidenceEnum.Principale,
      TarifPreferentiel:
        saisieHabitation.TarifPreferentiel !== undefined
          ? saisieHabitation.TarifPreferentiel
          : false,
      CompositionFoyer:
        saisieHabitation.CompositionFoyer !== undefined ? saisieHabitation.CompositionFoyer : null,
      RFRInferieurPlafond:
        saisieHabitation.RFRInferieurPlafond !== undefined
          ? saisieHabitation.RFRInferieurPlafond
          : null,
      ConfirmationRFRInferieurPlafond:
        saisieHabitation.ConfirmationRFRInferieurPlafond !== undefined
          ? saisieHabitation.ConfirmationRFRInferieurPlafond
          : null,
      NombrePieces: saisieHabitation.NombrePieces!,
      NombrePiecesSuperieures:
        saisieHabitation.NombrePiecesSuperieures !== undefined
          ? saisieHabitation.NombrePiecesSuperieures
          : null,
      Etage:
        saisieHabitation.Etage !== undefined && saisieHabitation.Etage !== Etage.Undefined
          ? saisieHabitation.Etage
          : null,
      AvecVeranda: saisieHabitation.AvecVeranda !== undefined ? saisieHabitation.AvecVeranda : null,
      AntecedentSinistresInondables:
        saisieHabitation.AntecedentSinistresInondables !== undefined
          ? saisieHabitation.AntecedentSinistresInondables
          : null,
      SurfaceDependance:
        saisieHabitation.SurfaceDependance == SurfaceDependance.PlusDe100m2
          ? VyVTarifSaisieHabitation.SurfaceDependanceEnum.PlusDe100m2
          : saisieHabitation.SurfaceDependance == SurfaceDependance.De50a100m2
          ? VyVTarifSaisieHabitation.SurfaceDependanceEnum.De50a100m2
          : saisieHabitation.SurfaceDependance == SurfaceDependance.MoinsDe50m2
          ? VyVTarifSaisieHabitation.SurfaceDependanceEnum.MoinsDe50m2
          : null,
      InstallationDD:
        saisieHabitation.InstallationDD !== undefined ? saisieHabitation.InstallationDD : null,
      PiscineSpaJacuzzi:
        saisieHabitation.PiscineSpaJacuzzi !== undefined
          ? saisieHabitation.PiscineSpaJacuzzi
          : null,
      ObjetsValeurSuperieur2000:
        saisieHabitation.ObjetsValeurSuperieur2000 !== undefined
          ? saisieHabitation.ObjetsValeurSuperieur2000
          : null,
      PlafondMobilier:
        saisieHabitation.PlafondMobilier == Formule.F2
          ? VyVTarifSaisieHabitation.PlafondMobilierEnum.F2
          : VyVTarifSaisieHabitation.PlafondMobilierEnum.F1
    };
  }

  static GenerateTarifCreateRequestBody(
    context: string,
    product: PathType,
    brand: string,
    adresseType: Selectionne,
    adresseSelection: Selection | undefined,
    adresseManuelle: Esh | undefined,
    adresseEsh: Esh | undefined,
    saisieHabitation: SaisieHabitation,
    saisieFormule: SaisieFormule | undefined,
    formuleRecommandee: Formule
  ): VyVTarifCreateRequest {
    const body: VyVTarifCreateRequest = {
      Context: context,
      SaisieAdresse: ApiRequestBodyUtils.GenerateSaisieAdresseData(
        adresseType,
        adresseSelection,
        adresseManuelle,
        adresseEsh
      ),
      SaisieHabitation: ApiRequestBodyUtils.GenerateSaisieHabitationData(saisieHabitation),
      SaisieProduit: {
        IdProduit: ApiRequestBodyUtils.GenerateProductData(product, brand),
        FormuleSelectionnee:
          saisieFormule !== undefined
            ? ApiRequestBodyUtils.ConvertCodeFormule(saisieFormule.FormuleSelectionnee)
            : null,
        FormuleRecommandee: ApiRequestBodyUtils.ConvertCodeFormule(formuleRecommandee),
        CodePromo: saisieFormule !== undefined ? saisieFormule.CodePromo || null : null
      },
      SaisiePersonnalisation: null
    };

    return body;
  }

  static ConvertCodeFormule(codeFormule: Formule | undefined): VyVSharedCodeFormule | null {
    switch (codeFormule) {
      case Formule.F1:
        return VyVSharedCodeFormule.F1;
      case Formule.F2:
        return VyVSharedCodeFormule.F2;
      case Formule.LM:
        return VyVSharedCodeFormule.LM;
      default:
        return null;
    }
  }

  static GenerateTarifCreateRequestBodyWithPersonnalisation(
    context: string,
    product: PathType,
    brand: string,
    adresseType: Selectionne,
    adresseSelection: Selection | undefined,
    adresseManuelle: Esh | undefined,
    adresseEsh: Esh | undefined,
    saisieHabitation: SaisieHabitation,
    saisieFormule: SaisieFormule,
    formuleRecommandee: Formule,
    saisiePersonnalisation: SaisiePersonnalisation
  ): VyVTarifCreateRequest {
    let body = ApiRequestBodyUtils.GenerateTarifCreateRequestBody(
      context,
      product,
      brand,
      adresseType,
      adresseSelection,
      adresseManuelle,
      adresseEsh,
      saisieHabitation,
      saisieFormule,
      formuleRecommandee
    );

    // Insert personnalisation data
    body.SaisiePersonnalisation = {
      ProtectionJuridique: saisiePersonnalisation.ProtectionJuridique,
      ProtectionNomades: saisiePersonnalisation.ProtectionNomades,
      ProtectionVelos: saisiePersonnalisation.ProtectionVelos,
      MontantValeurObjets2000:
        saisiePersonnalisation.MontantValeurObjets2000 == MontantValeurObjets2000.Montant20000
          ? VyVTarifSaisiePersonnalisation.MontantValeurObjets2000Enum.Montant15000
          : saisiePersonnalisation.MontantValeurObjets2000 == MontantValeurObjets2000.Montant10000
          ? VyVTarifSaisiePersonnalisation.MontantValeurObjets2000Enum.Montant10000
          : VyVTarifSaisiePersonnalisation.MontantValeurObjets2000Enum.Montant5000,
      AssuranceScolaire:
        saisiePersonnalisation.AssuranceScolaire == null
          ? []
          : saisiePersonnalisation.AssuranceScolaire.map(
              a =>
                ({
                  Numero: a.Numero,
                  Nom: a.Nom,
                  Prenom: a.Prenom,
                  DateNaissance: a.DateNaissance
                } as VyVTarifEnfantInfo)
            )
    };

    return body;
  }

  static GenerateDevisCreateRequestBody(
    context: string,
    clientAdvisorFullName: string,
    product: PathType,
    brand: string,
    adresseType: Selectionne,
    adresseSelection: Selection | undefined,
    adresseManuelle: Esh | undefined,
    adresseEsh: Esh | undefined,
    saisieHabitation: SaisieHabitation,
    souscripteurInfos: SaisieInfosSouscripteur,
    saisieFormule: SaisieFormule,
    formuleRecommandee: Formule
  ): VyVDevisCreateRequest {
    if (saisieFormule.FormuleSelectionnee === undefined) {
      throw new Error('saisieFormule.FormuleSelectionnee not set');
    }
    return {
      Context: context,
      ClientAdvisorFullName: clientAdvisorFullName,
      SaisieHabitation: ApiRequestBodyUtils.GenerateSaisieHabitationData(saisieHabitation),
      SaisieFormule: {
        IdProduit: ApiRequestBodyUtils.GenerateProductData(product, brand),
        FormuleSelectionnee: ApiRequestBodyUtils.ConvertCodeFormule(
          saisieFormule.FormuleSelectionnee
        )!,
        FormuleRecommandee: ApiRequestBodyUtils.ConvertCodeFormule(formuleRecommandee)!
      },
      SaisieInfosSouscripteur: {
        Adresse: ApiRequestBodyUtils.GenerateSaisieAdresseData(
          adresseType,
          adresseSelection,
          adresseManuelle,
          adresseEsh
        ),
        Civilite: souscripteurInfos?.Civilite,
        Nom: souscripteurInfos?.Nom,
        Prenom: souscripteurInfos?.Prenom,
        DateNaissance: souscripteurInfos?.DateNaissance,
        VilleNaissance: souscripteurInfos?.LieuNaissance,
        PaysNaissance: souscripteurInfos?.PaysNaissance,
        Email: souscripteurInfos?.Email,
        Telephone: souscripteurInfos?.Indicatif + '-' + souscripteurInfos?.Telephone
      }
    };
  }

  static GenerateCheckIbanRequestBody(context: string, iban: string): VyVContractCheckIBANRequest {
    return {
      Context: context,
      IBAN: iban
    };
  }

  static GenerateContractCreateRequestBody(
    context: string,
    devisId: string,
    beneficiaire: SaisieBeneficiaire | null,
    saisieChoixServices: SaisieChoixServices,
    saisiePaiement: SaisiePaiement
  ): VyVContractCreateRequest {
    return {
      Context: context,
      DevisId: devisId,
      SaisieBeneficiaire:
        beneficiaire !== null
          ? {
              BeneficiaireTiers: {
                Civilite: beneficiaire.Civilite,
                Nom: beneficiaire.Nom,
                Prenom: beneficiaire.Prenom,
                Email: beneficiaire.Email,
                Telephone: beneficiaire.Telephone
              }
            }
          : null,
      SaisieChoixServices: {
        EnvoiAttestationBailleur:
          saisieChoixServices.EnvoiAttestationBailleur !== undefined && saisieChoixServices.EnvAttB
            ? {
                TypeBailleur:
                  saisieChoixServices.EnvoiAttestationBailleur.TypeBailleur == TypeBailleur.Prive
                    ? VyVContractEnvoiAttestationBailleurInfo.TypeBailleurEnum.Prive
                    : VyVContractEnvoiAttestationBailleurInfo.TypeBailleurEnum.Social,
                BailleurPrive:
                  saisieChoixServices.EnvoiAttestationBailleur.TypeBailleur == TypeBailleur.Prive
                    ? {
                        NomBailleur: null,
                        Email: saisieChoixServices.EnvoiAttestationBailleur.BailleurPrive!.Email
                      }
                    : null,
                BailleurSocial:
                  saisieChoixServices.EnvoiAttestationBailleur.TypeBailleur == TypeBailleur.Social
                    ? {
                        NomBailleur:
                          saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial!.NomBailleur,
                        Email: saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial!.Email,
                        Reference1:
                          saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial!.Reference1,
                        Reference2:
                          saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial!.Reference2,
                        LogementOccupe:
                          saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial
                            ?.LogementOccupe ?? null
                      }
                    : null
              }
            : null,
        ResiliationAncienneAssurance:
          saisieChoixServices.ResiliationAncienneAssurance !== undefined &&
          saisieChoixServices.ResAncAss
            ? {
                Raison:
                  saisieChoixServices.ResiliationAncienneAssurance.Raison === Raison.Emmenagement
                    ? VyVContractResiliationAncienneAssuranceInfo.RaisonEnum.Emmenagement
                    : VyVContractResiliationAncienneAssuranceInfo.RaisonEnum.ChangementAssureur,
                NomAssureur:
                  saisieChoixServices.ResiliationAncienneAssurance.Raison ===
                  Raison.ChangementAssureur
                    ? saisieChoixServices.ResiliationAncienneAssurance.NomAssureur
                    : null,
                AdresseAssureur:
                  saisieChoixServices.ResiliationAncienneAssurance.Raison ===
                  Raison.ChangementAssureur
                    ? {
                        CodePostal:
                          saisieChoixServices.ResiliationAncienneAssurance.CodePostalAssureur,
                        Ville: saisieChoixServices.ResiliationAncienneAssurance.VilleAssureur,
                        NumeroEtRue:
                          saisieChoixServices.ResiliationAncienneAssurance.AddressAssureur
                      }
                    : null,
                NumeroContrat:
                  saisieChoixServices.ResiliationAncienneAssurance.Raison ===
                  Raison.ChangementAssureur
                    ? saisieChoixServices.ResiliationAncienneAssurance.NumeroContrat
                    : null,
                MoisDemarrageContrat:
                  saisieChoixServices.ResiliationAncienneAssurance.Raison ===
                  Raison.ChangementAssureur
                    ? saisieChoixServices.ResiliationAncienneAssurance.MoisDemarrageContrat
                    : null,
                DateEnvoiLR:
                  saisieChoixServices.ResiliationAncienneAssurance.Raison ===
                  Raison.ChangementAssureur
                    ? saisieChoixServices.ResiliationAncienneAssurance.DateEnvoiLR
                    : null
              }
            : null,
        OppositionMarketing: saisieChoixServices.OppositionMarketing,
        NombreEnfants: saisieChoixServices.NombreEnfants
      },
      SaisiePaiement: {
        EcheancePrelevement:
          saisiePaiement.EcheancePrelevement == EcheancePrelevement.Trimestriel
            ? VyVContractSaisiePaiement.EcheancePrelevementEnum.Trimestriel
            : saisiePaiement.EcheancePrelevement == EcheancePrelevement.Annuel
            ? VyVContractSaisiePaiement.EcheancePrelevementEnum.Annuel
            : VyVContractSaisiePaiement.EcheancePrelevementEnum.Mensuel,
        DateDemarrage: saisiePaiement.DateDemarrage!,
        JourPrelevement:
          saisiePaiement.JourPrelevement === '5'
            ? VyVContractSaisiePaiement.JourPrelevementEnum.Le5DuMois
            : saisiePaiement.JourPrelevement === '10'
            ? VyVContractSaisiePaiement.JourPrelevementEnum.Le10DuMois
            : saisiePaiement.JourPrelevement === '15'
            ? VyVContractSaisiePaiement.JourPrelevementEnum.Le15DuMois
            : VyVContractSaisiePaiement.JourPrelevementEnum.Le5DuMois,
        IBAN: saisiePaiement.IBAN,
        Telephone: saisiePaiement.Telephone,
        TitulaireCompte: saisiePaiement.TitulaireCompte!
      }
    };
  }

  static GenerateSignatureCreateRequestBody(
    context: string,
    contractId: string,
    saisieSignature: SaisieSignature
  ): VyVSignatureCreateRequest {
    return {
      Context: context,
      ContractId: contractId,
      AcceptationCP: saisieSignature.AcceptationCP,
      AcceptationFIC: saisieSignature.AcceptationFIC,
      AcceptationMandatSEPA: saisieSignature.AcceptationMandatSEPA
    };
  }

  static GenerateSignatureSignRequestBody(
    context: string,
    contractId: string,
    saisieSignature: SaisieSignature
  ): VyVSignatureSignRequest {
    return {
      Context: context,
      ContractId: contractId,
      TokenSignature: saisieSignature.SignatureToken!,
      CodeSMS: saisieSignature.CodeSms!
    };
  }

  static GeneratePostConsentRequestBody(
    context: string,
    idLibelle: number,
    Voies: VoiesConsentement[]
  ): VyVConsentPostRequest {
    return {
      sessionId: context,
      idLibelle: idLibelle,
      voies: Voies.map(v => ({
        id: v.Id,
        isAccepted: v.IsCheck ?? false
      }))
    };
  }
}
