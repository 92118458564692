import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { SaisieInfosSouscripteur } from 'src/app/core/models/form-state.model';
import { TOKEN } from 'src/app/shared/constants/localstorage.constants';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  basePath: string;
  constructor(private http: HttpClient, configuration: Configuration) {
    this.basePath = configuration.apiBasePath ?? '';
  }

  public getByEmail(email: string): Observable<SaisieInfosSouscripteur> {
    const url = `${this.basePath}members/${email}`;
    return this.http.get<SaisieInfosSouscripteur>(url);
  }

  public getUserInfoAuth(): Observable<SaisieInfosSouscripteur> {
    const token = JSON.parse(localStorage.getItem(TOKEN) || '""');
    let header;
    if (token) {
      header = new HttpHeaders({
        Authorization: `Bearer ${token}`
      });
    }
    return this.http.get<SaisieInfosSouscripteur>(`${this.basePath}members/me`, {
      headers: header
    });
  }

  public emailExists(email: string): Observable<boolean> {
    const url = `${this.basePath}members/${email}`;
    return this.http.head(url, { observe: 'response' }).pipe(
      map(response => {
        if (response.status === 200) {
          return true;
        } else if (response.status === 204) {
          return false;
        } else {
          throw new Error(`Unexpected status code: ${response.status}`);
        }
      }),
      catchError(error => {
        console.error('Error:', error);
        return throwError('Error occurred during HTTP request.');
      })
    );
  }

  public isEnrolled(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.basePath}members/${email}/enrollmentStatus`);
  }

  public sendEmailInitEspaceAdherent(email: string, type: string): Observable<void> {
    const encodedEmail = encodeURIComponent(email);
    return this.http.post<void>(
      `${this.basePath}members/member:send?email=${encodedEmail}&type=${type}`,
      {}
    );
  }

  public emailIsValid(email: string): Observable<boolean> {
    const url = `${this.basePath}members/${email}/validate`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map(response => {
        if (response.status === 200) {
          console.debug('Email validation succeeded');
          return true;
        } else {
          console.error(`Unexpected status code: ${response.status}`);
          return false;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 422) {
          console.error('Email validation failed:', email);
          return of(false);
        }

        console.error('Error:', error);
        return throwError(() => new Error('Error occurred during HTTP request.'));
      })
    );
  }

  public passwordReset(email: string): Observable<void> {
    return this.http.post<void>(`${this.basePath}members/passwordReset`, { email });
  }
}
